import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_Home_Assistant_Tutorials/PrimaryBox.jsx';
import PrimaryBox from 'components/Advanced_User/Home_Assistant_on_Raspberry_Pi/PrimaryBox';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Home Assistant on Raspberry Pi",
  "path": "/Advanced_User/Home_Assistant_on_Raspberry_Pi/",
  "dateChanged": "2018-03-26",
  "author": "Mike Polinowski",
  "excerpt": "Home Assistant is a open source home automation that puts local control and privacy first. Powered by a worldwide community of tinkerers and DIY enthusiasts. Perfect to run on a Raspberry Pi or a local server. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_HomeAssistant.png",
  "social": "/images/Search/AU_SearchThumb_HomeAssistant.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_HomeAssistant_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "Home Assistant"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Home Assistant on Raspberry Pi' dateChanged='2018-03-26' author='Mike Polinowski' tag='INSTAR IP Camera' description='Home Assistant is a open source home automation that puts local control and privacy first. Powered by a worldwide community of tinkerers and DIY enthusiasts. Perfect to run on a Raspberry Pi or a local server.' image='/images/Search/AU_SearchThumb_HomeAssistant.png' twitter='/images/Search/AU_SearchThumb_HomeAssistant.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/Home_Assistant_on_Raspberry_Pi/' locationFR='/fr/Advanced_User/Home_Assistant_on_Raspberry_Pi/' crumbLabel="Home Assistant" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "home-assistant-on-raspberry-pi",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#home-assistant-on-raspberry-pi",
        "aria-label": "home assistant on raspberry pi permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Home Assistant on Raspberry Pi`}</h1>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#hassio"
        }}>{`Hassio`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#manual-installation"
        }}>{`Manual Installation`}</a></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "hassio",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#hassio",
        "aria-label": "hassio permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hassio`}</h2>
    <p>{`Installing the `}<a parentName="p" {...{
        "href": "https://www.home-assistant.io/installation/raspberrypi"
      }}>{`Raspberry Pi Linux image`}</a>{` on your Raspberry Pi:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/f3f93ace23908e376a347ebc20946be8/e9beb/Hassio_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "109.56521739130436%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAWCAIAAABPIytRAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADs0lEQVQ4y4VQ22scZRzdf8c38a0iYnbLbrI795mdvSTbbDfEaJtAqGIsQdEXUaT0ohRfagIK+mSsIFRMqtaHYl8UrUlM9z7z7WUuO7fv+2ZmdzLR2awxKK1nDj8OH+fw+82JhUcRds1RTTENXVNVtdfrYYyPHo9hENpe4AdhbBQER2Hwu+61NMexTNM0dV13XffYF4bh4eFhcApHYTAcDS3s+8NRDI1h2EgGHVmWqrVatVaTZflRtSpJkizLmq4jhCFEECIEUc9wWqolaVZ3YMcgQhhBaYAe/PzL93e3d7a3v7lz57uduz/eu7ezs/3gp/v1esP3PAShi5GHkeUg3YK6BQ0bTjY7CEM/wEGIg9ANjuAwwKMQj0I4DCzsDxw8gFizoGZB3YYDO5qmMw5jjHuK2mhLrbbUlGQJdCQZtMei2ZbbcqclRdOwbMuBJ7Th35tNw+h2u0CWOwB0Op1ojoXS72uapiqKqijQcXD0j/9wEsYYK4rabrclSW5LciQAaEkSRMjzfXcM9B/ETpTnupZtAVkGjYYCAGg2DFXFjoNMc1w0elIYwehT9g6qu7XmQav9qGlLMup0EehAABDGTwyPj9cazYdd+w/F2es7BsTYdZHrQhn83+ZxWK01d4FR7Vt7XbOhWPAYjwufbs/FWKs397tmXbH3e1ZdtT3X9T0XA/CvnidtO77reBNC3+vXG79K2n5n8JusPwR6rW8cdAeaBKDvndgmZs+N0Z98mPri1vSXGxFvbyY//SC+cS2xeSO+eT3ixvWpj68lP/9o+vbmxLO1MbO1kdi6df7rz2LxNDuV4aYILkHxcZKL00KCyR7zLCsmWDHBZOOUMEVyUyQXJ/lIENxZOltZWY3RTJ6mcwyTT6XodJqj6RxFiRQlMkwumaROv9B0jiAEksoybJ7I8ItLKzGSyVFsPpnh3nz7nZVLawSTyxXKYrE8TQpXb9xcffVyhs7liufFQpmksxeWL82WFjKkMEPwlaXlKEyz+UyKfv2Vy8tLK8QMy/BFhisQM8z62vpLCy8TGY7hizRfJJi8WFrk8mWCyc9kxmGCyYkkf3GuMiuW5sT5C8UyQ/AclV2Zq8yJ85XZhcXcOX6afGFh/an37z/77rfPvPfD0299laLylRcvRuEslS1RQiE7XxDOlSiBpUSOEucpoSiWi0JpjhQ4kk+LlTOLb9xMFl5jFp9bWEsTwuTs6HJaZJmoJIoWj18oWmQYkR0LIjIIKZq/cub51Xhqms2mM9xf4T8Bh9G+/gX2zIYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f3f93ace23908e376a347ebc20946be8/e4706/Hassio_01.avif 230w", "/en/static/f3f93ace23908e376a347ebc20946be8/d1af7/Hassio_01.avif 460w", "/en/static/f3f93ace23908e376a347ebc20946be8/b6582/Hassio_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/f3f93ace23908e376a347ebc20946be8/a0b58/Hassio_01.webp 230w", "/en/static/f3f93ace23908e376a347ebc20946be8/bc10c/Hassio_01.webp 460w", "/en/static/f3f93ace23908e376a347ebc20946be8/87ca7/Hassio_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/f3f93ace23908e376a347ebc20946be8/81c8e/Hassio_01.png 230w", "/en/static/f3f93ace23908e376a347ebc20946be8/08a84/Hassio_01.png 460w", "/en/static/f3f93ace23908e376a347ebc20946be8/e9beb/Hassio_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/f3f93ace23908e376a347ebc20946be8/e9beb/Hassio_01.png",
              "alt": "Home Assistant auf einem Raspberry Pi",
              "title": "Home Assistant auf einem Raspberry Pi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Download the program `}<a parentName="p" {...{
        "href": "https://www.balena.io/etcher"
      }}>{`Etcher`}</a>{` and flash the following image onto your SD Card:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`https://github.com/home-assistant/operating-system/releases/download/5.13/hassos_rpi4-64-5.13.img.xz`}</code></pre></div>
    <p>{`Insert the SD card into your Raspberry Pi and plug in the power supply to boot up the system. This will automatically start the installation process.`}</p>
    <p>{`Within a few minutes you will be able to reach Home Assistant on homeassistant.local:8123. If you are running an older Windows version or have a stricter network configuration, you might need to access Home Assistant at homeassistant:8123 or `}<a parentName="p" {...{
        "href": "http://X.X.X.X:8123"
      }}>{`http://X.X.X.X:8123`}</a>{` (replace X.X.X.X with your Raspberry Pi’s IP address).`}</p>
    <p>{`Now continue with the `}<a parentName="p" {...{
        "href": "https://www.home-assistant.io/getting-started/onboarding/"
      }}>{`OnBoarding Steps`}</a>{`.`}</p>
    <h2 {...{
      "id": "manual-installation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#manual-installation",
        "aria-label": "manual installation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Manual Installation`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/24dd9f1c45278c20d4e04e984448faec/e9beb/HomeAssistant_Windows_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAADYUlEQVQozwFWA6n8AFFSVFNWWKirrsDBw7u7vLu8vru8vb/CwcWYl8OIh8CMib2Eg8GWlcbLycbIxsfHycfHysbGycfHysfGyAAmLjQzPUMlLjQqMjkwNj0zOT8tNDszODo5OTg8Ojo2NTQxMzQxNjg2P0AxOjsxNz4zN0E4PEUyNkAyNDwAGDNPHjdNGDBDHTRIGTFGGzJHGjJHGTFHGTJIGTRKGDJIGTJIGDFGESg+DSQ6DSU5DSU5DiY6Dyc7GDBEAGuCe1FjY0dedUxhdUxidjlRZyA9ViA9ViA9Vh88VR89VyE5Tys0PpiepLi+w7W7wLW7wLvAxZ2kqjRWdQBcZlU3TVk0UGgoR2ApSGEmRl4hPlggPVchPlciP1gjQFohOlImNUJmcn11gYxyfotyfop3hI5mc38nQFgALWGXNWuZO1iDVFR4UVh8SFF5MWCMNGSQNGOOLF2LKVyJMWKOL2GPGk9/HVB/H1KAIFKBF0t8IFOCLV6KAC9NaixJY05igImMqIWLpX2Ip2yRsm+UtYKgu4qkvYOgu42nv42mvoWguo2owJCqwpCqwoikvo2pwYGeuAAfKTIVHBxhc3671uyuxtq30ui60+ezzuTd6PLv8vbl6/Ho7vPj7PLv9vr////////////////////o6+8AIC84FCAhYXJ/vtbrscjcuNDkutLmtc7l1+Tv3t/f0dHS3eXm74mP7mlw8dre8vv/8fX48fb58/f72+TqACAvNhMdH2NygMLY67jO4cDX6r/W6bzU6NXj79zg5NTZ3dng5eLL0eHEyuDg5eHo7d7k6d7k6ebt8tni6gAgLzcSGx9lcn/H3O27z+DD2OrE2erC2OnT4u/V3OLR2eDe5+7d6/Lc7PPc5u3c5Ovc5Ozb4+vk7fXU3ugAIS84EyAfaHmBzODwvM7dw9bmyd3sxtvr0eHu1eDp0Nzm0Nvl0Nrk0dnj1N7o1N7n2eTt3Ojx4Ov0y9nlACczPSQrLnF/iNLj8srb6c/g8M3f7s3e7s/f7c7e7Mvc68zd7Mzd7Mzc7NXj8Nfl8dfl8djm8tnm88TU4gAjM0EZJC5te4jT4/HE0+HG1ePG1uPI2ObO3+3U4/HU4/HT4/HU4/HU4/HS4vDS4vDS4vDS4vDU4/HA0eKZOesk2E+r5QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24dd9f1c45278c20d4e04e984448faec/e4706/HomeAssistant_Windows_01.avif 230w", "/en/static/24dd9f1c45278c20d4e04e984448faec/d1af7/HomeAssistant_Windows_01.avif 460w", "/en/static/24dd9f1c45278c20d4e04e984448faec/b6582/HomeAssistant_Windows_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/24dd9f1c45278c20d4e04e984448faec/a0b58/HomeAssistant_Windows_01.webp 230w", "/en/static/24dd9f1c45278c20d4e04e984448faec/bc10c/HomeAssistant_Windows_01.webp 460w", "/en/static/24dd9f1c45278c20d4e04e984448faec/87ca7/HomeAssistant_Windows_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/24dd9f1c45278c20d4e04e984448faec/81c8e/HomeAssistant_Windows_01.png 230w", "/en/static/24dd9f1c45278c20d4e04e984448faec/08a84/HomeAssistant_Windows_01.png 460w", "/en/static/24dd9f1c45278c20d4e04e984448faec/e9beb/HomeAssistant_Windows_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/24dd9f1c45278c20d4e04e984448faec/e9beb/HomeAssistant_Windows_01.png",
              "alt": "Home Assistant auf einem Raspberry Pi",
              "title": "Home Assistant auf einem Raspberry Pi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b7716b5f28fa39a13368e69c65c344ad/e9beb/HomeAssistant_Windows_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "61.30434782608696%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACIklEQVQoz4WSyW7UQBCG/WS8AdwRggsSyQSOSLwFh7wEEhKERSEEaWaIFBBk0ewJnvHS9ngZ293tbttjd9vubpRkIhAXPn2q268qVZV2/8u7ewdv7x68edTbe3z8cWvU35kcbQ97nVH/6eRoa9h7MuhuDXrbo/7O+Kgz6neGvc7wug662qken+uwd2JVmKqScZxGtpN6QWQ7xA/LGCrWZMEKOR7yQ6WUlFLdon0+nx+e6qbrEwwJpVGSmADYwLEAAO7SsIDluGDprZIkiOIYIUQIJkQqJaTQaE5bVhpuGCWkYhxfXoYnZ2g8Q8NJPpvR8SQdjYuLCwnAjcIGknN1PYNG8mz/xLrz/HX3e1fWJD0fhBF2IQ8wK6uGVQ1jzbqss5xlWZVVojCBxHgTtkL8bPfV3ocXydmDMp1y3YY5XyQcoHqRcAvWRlJHeVs2cs3EWqnKDf6EI5QH3kC5D1l4QPJ1Yxj5dA6nBp4urpwt4vG81A1lWfJKW/yay6rahDGllKSurccJLoqC1826lhlrCy5I1VImbrYrb/0bjRR5WfE19Vn6TWQ/mprEhbQRd1BtQm4izhuh/uH2Wpob+KEfZOG+KL9K9lMKqjad/o/meP4SmCv7fZ19arL9FFlxkkKIEEIpoSiB2LbTBGKE21bcPEld14yxtm21II6Xjgnmh2l83JSnKfY8L1ytVgjBGGFkWPnLXTZfNEpJsQkHQaDrOqXZb1bIiAkmXZi5AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b7716b5f28fa39a13368e69c65c344ad/e4706/HomeAssistant_Windows_02.avif 230w", "/en/static/b7716b5f28fa39a13368e69c65c344ad/d1af7/HomeAssistant_Windows_02.avif 460w", "/en/static/b7716b5f28fa39a13368e69c65c344ad/b6582/HomeAssistant_Windows_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b7716b5f28fa39a13368e69c65c344ad/a0b58/HomeAssistant_Windows_02.webp 230w", "/en/static/b7716b5f28fa39a13368e69c65c344ad/bc10c/HomeAssistant_Windows_02.webp 460w", "/en/static/b7716b5f28fa39a13368e69c65c344ad/87ca7/HomeAssistant_Windows_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b7716b5f28fa39a13368e69c65c344ad/81c8e/HomeAssistant_Windows_02.png 230w", "/en/static/b7716b5f28fa39a13368e69c65c344ad/08a84/HomeAssistant_Windows_02.png 460w", "/en/static/b7716b5f28fa39a13368e69c65c344ad/e9beb/HomeAssistant_Windows_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b7716b5f28fa39a13368e69c65c344ad/e9beb/HomeAssistant_Windows_02.png",
              "alt": "Home Assistant auf einem Raspberry Pi",
              "title": "Home Assistant auf einem Raspberry Pi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cafea1143743f90604b91c16b1ca5157/e9beb/HomeAssistant_Windows_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "13.478260869565217%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7EAAAOxAGVKw4bAAAAkklEQVQI12MwcYkydg43c4kydgo3dgzXsvBVM/OW13chBjHYuET52Ae5Wvo6W3i7WXibmnoqGnsqGbkrm3gqG3sqG3soG3uomHioGHsqm3iqgBFEUNnYgyE6pyU+vy0muykkpSo0pdrKM97ENVrfPlTT0k/HJlDLKkDLyl/bOlDbOlDHJlDbOkAbLKhpFaBp6QcA7J8rgfOINsIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cafea1143743f90604b91c16b1ca5157/e4706/HomeAssistant_Windows_03.avif 230w", "/en/static/cafea1143743f90604b91c16b1ca5157/d1af7/HomeAssistant_Windows_03.avif 460w", "/en/static/cafea1143743f90604b91c16b1ca5157/b6582/HomeAssistant_Windows_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cafea1143743f90604b91c16b1ca5157/a0b58/HomeAssistant_Windows_03.webp 230w", "/en/static/cafea1143743f90604b91c16b1ca5157/bc10c/HomeAssistant_Windows_03.webp 460w", "/en/static/cafea1143743f90604b91c16b1ca5157/87ca7/HomeAssistant_Windows_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cafea1143743f90604b91c16b1ca5157/81c8e/HomeAssistant_Windows_03.png 230w", "/en/static/cafea1143743f90604b91c16b1ca5157/08a84/HomeAssistant_Windows_03.png 460w", "/en/static/cafea1143743f90604b91c16b1ca5157/e9beb/HomeAssistant_Windows_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cafea1143743f90604b91c16b1ca5157/e9beb/HomeAssistant_Windows_03.png",
              "alt": "Home Assistant auf einem Raspberry Pi",
              "title": "Home Assistant auf einem Raspberry Pi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/2bb11e00299bf3252a6644021517aa93/e9beb/HomeAssistant_Windows_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.826086956521735%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABj0lEQVQY0y2Q6W6bQACEeZI6qY2pa+NyxIA5l2Mxxy7sghtkp7XLjfP+D1CRRBp9ml/fSMMc4hsMLiUoX8PrNblVp7fX8PqZ0q+SI8YmiTWUHHFmUagmKwE+ieFSjp7FE2Pi1kSNT4aATj4dfTrC8uFmvUcGgFsHt0Z806O/Dm7N+H4ILi/wTXSrrUHWCmIM1Bi4degI6GSTwc4HUExm1ltZb2adlfc6akAxgWKy8t47v6vRXfSqhQAXvwLGSGsDNQ4ZXDo6ZJhL8bCyzs77mVlnZR2go00GQEe3fBzgn51JN8d8IUDGyjoLt4AMfvkA8/7on98/LXbez8a894rJKyb7o9hZZ6S16FbPYsho8V2Lbnpam7g10sZAjZ33elpbuNXTWkONkv77aZ9XGmZVxKporeKVglgl/S6dmLWC1griVMSp+IOIPSRLKVrKMSdHshwpUijxrsC7PO9teW+z93a8x+39bwJkdiaV/K8PX+BV8i975/dWJz8MshHD484R9572FZcTgqUA2ZnBkwD/A5Arg9eFEGGIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2bb11e00299bf3252a6644021517aa93/e4706/HomeAssistant_Windows_04.avif 230w", "/en/static/2bb11e00299bf3252a6644021517aa93/d1af7/HomeAssistant_Windows_04.avif 460w", "/en/static/2bb11e00299bf3252a6644021517aa93/b6582/HomeAssistant_Windows_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/2bb11e00299bf3252a6644021517aa93/a0b58/HomeAssistant_Windows_04.webp 230w", "/en/static/2bb11e00299bf3252a6644021517aa93/bc10c/HomeAssistant_Windows_04.webp 460w", "/en/static/2bb11e00299bf3252a6644021517aa93/87ca7/HomeAssistant_Windows_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/2bb11e00299bf3252a6644021517aa93/81c8e/HomeAssistant_Windows_04.png 230w", "/en/static/2bb11e00299bf3252a6644021517aa93/08a84/HomeAssistant_Windows_04.png 460w", "/en/static/2bb11e00299bf3252a6644021517aa93/e9beb/HomeAssistant_Windows_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/2bb11e00299bf3252a6644021517aa93/e9beb/HomeAssistant_Windows_04.png",
              "alt": "Home Assistant auf einem Raspberry Pi",
              "title": "Home Assistant auf einem Raspberry Pi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/4384daa5309aa84295685b5aa299d7d2/e9beb/HomeAssistant_Windows_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "67.3913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABZUlEQVQoz32QS0sDMRDH/b4i9tCLoIIXDwXBg3hRWwXtR/DmURBf+GjVQxUFtZtH3exuMkk2m4nsFnx2+2PIIZkf/8nMNJvNxvz8aqt1st893tg83W7fdHYvdzrn7b2Lzt7Zdudqv9u/7fX699c3t/27/lb7YHZuYXl5qdFozITfFBiOIveUOaMhg7IkgEgEAIR/fMtYnTmGwxcYxFalIhZCiCSOY855lslaGbF0sywbvr8xEjEaDYdDRimlhFEWRYRRaq2dJgMoQkhECGWUkNLnnBMSccbTNDXG1I4dQrDWSqm0BmOM0VqDttZqrf9nTh6bMsoYk0pVlx69R0TvfVExTTbGCCGcc+PWIs/LqvAV02QpM875V06BWNYPxm01C1NqNBqNEzCE4vWteHrG6nnan8c450BBnjtXLRbW1mFxBUPIjXEVk5P/gN4nSorB48f9g7Im1DBZ9oiJEKmGBJSSsk7+BNo45Cy8b5a6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4384daa5309aa84295685b5aa299d7d2/e4706/HomeAssistant_Windows_05.avif 230w", "/en/static/4384daa5309aa84295685b5aa299d7d2/d1af7/HomeAssistant_Windows_05.avif 460w", "/en/static/4384daa5309aa84295685b5aa299d7d2/b6582/HomeAssistant_Windows_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/4384daa5309aa84295685b5aa299d7d2/a0b58/HomeAssistant_Windows_05.webp 230w", "/en/static/4384daa5309aa84295685b5aa299d7d2/bc10c/HomeAssistant_Windows_05.webp 460w", "/en/static/4384daa5309aa84295685b5aa299d7d2/87ca7/HomeAssistant_Windows_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/4384daa5309aa84295685b5aa299d7d2/81c8e/HomeAssistant_Windows_05.png 230w", "/en/static/4384daa5309aa84295685b5aa299d7d2/08a84/HomeAssistant_Windows_05.png 460w", "/en/static/4384daa5309aa84295685b5aa299d7d2/e9beb/HomeAssistant_Windows_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/4384daa5309aa84295685b5aa299d7d2/e9beb/HomeAssistant_Windows_05.png",
              "alt": "Home Assistant auf einem Raspberry Pi",
              "title": "Home Assistant auf einem Raspberry Pi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/b10c5bca109c0e78a0e2bf7627f21d52/e9beb/HomeAssistant_Windows_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.86956521739131%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA1klEQVQoz42RW46DMAxF2f+i+j+zh5EG8QoNDCFxgkP9qFp+EJVgjq6iWNGVb+xCd8j7/OrzrV7WJQbvAQJAmJ3LOesHxb5gEVX5NulWwZLi8OcmN4/TbK0FAJFzM1NvTN+2pm2qfhwjT6gua0xL8CHGdGYWkWGw97ttG1OWZVPXpu2899vTRWxVRUQi0v9xiM2d6QCAmekF7/XZ/BBbAxLmhzAfxEQZ8cLsQnIRkfQlfos0s7qIP7/V4fPFYc95s5EgSVoprbTdkeQhVwPb4+c5QTwZ2BMbZEv8AVa+8gAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b10c5bca109c0e78a0e2bf7627f21d52/e4706/HomeAssistant_Windows_06.avif 230w", "/en/static/b10c5bca109c0e78a0e2bf7627f21d52/d1af7/HomeAssistant_Windows_06.avif 460w", "/en/static/b10c5bca109c0e78a0e2bf7627f21d52/b6582/HomeAssistant_Windows_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/b10c5bca109c0e78a0e2bf7627f21d52/a0b58/HomeAssistant_Windows_06.webp 230w", "/en/static/b10c5bca109c0e78a0e2bf7627f21d52/bc10c/HomeAssistant_Windows_06.webp 460w", "/en/static/b10c5bca109c0e78a0e2bf7627f21d52/87ca7/HomeAssistant_Windows_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/b10c5bca109c0e78a0e2bf7627f21d52/81c8e/HomeAssistant_Windows_06.png 230w", "/en/static/b10c5bca109c0e78a0e2bf7627f21d52/08a84/HomeAssistant_Windows_06.png 460w", "/en/static/b10c5bca109c0e78a0e2bf7627f21d52/e9beb/HomeAssistant_Windows_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/b10c5bca109c0e78a0e2bf7627f21d52/e9beb/HomeAssistant_Windows_06.png",
              "alt": "Home Assistant auf einem Raspberry Pi",
              "title": "Home Assistant auf einem Raspberry Pi",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      